import { graphql } from "gatsby";

import StopBai from "../features/unsubscribe/ui/pages/StopBai";

const Page = (): JSX.Element => {
  return <StopBai />;
};

export default Page;

export const query = graphql`
  query StopBaiPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
