import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";

import PageStepAnimation from "../../../../atoms/page-step-animation/PageStepAnimation";
import Icons from "../../../../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../organisms/meta/meta";
import Navigation from "../../../../organisms/navigation/Funnel";
import PageStepForm, {
  PageStepFormDataElement,
} from "../../../../organisms/page-step-form/PageStepForm";
import PageWrapper from "../../../../organisms/page-wrapper/PageWrapper";
import { Locale } from "../../../../settings/countries";
import { Events, trackWithPromise } from "../../../../utils/analytics";
import { getCurrentURLSearchParams, getWindow } from "../../../../utils/browser-features";
import useAxeptioScriptLoader from "../../../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../../../utils/locale-configuration-utils";
import { rollbar } from "../../../../utils/rollbar";
import * as styles from "./StopBai.module.scss";

interface StopBaiFormValues {
  isBaiUnsubscribed: "yes" | "no" | null;
}

const StopBai = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const countryCodeIso = getCountryByLocale(i18n.language as Locale);
  const currentURLSearchParams = getCurrentURLSearchParams();
  const petParentKbKey = currentURLSearchParams?.get("pet_parent_kb_key");
  const [submitMessage, setSubmitMessage] = useState("");

  useAxeptioScriptLoader(i18n.language);

  const StopBaiFormData: PageStepFormDataElement = {
    initialValues: {
      isBaiUnsubscribed: null,
    },
    handleSubmit: async (
      values: StopBaiFormValues,
      { setSubmitting }: FormikHelpers<StopBaiFormValues>
    ): Promise<void> => {
      if (!values.isBaiUnsubscribed) {
        return;
      }

      setSubmitting(true);

      const w: Window | null = getWindow();

      if (!w || !petParentKbKey) {
        return;
      }

      const analytics = w.analytics;

      try {
        analytics.identify(petParentKbKey, {
          externalId: petParentKbKey,
          cfKbKey: petParentKbKey,
          country: countryCodeIso,
        });
        await trackWithPromise(Events.FORM_ANSWERED, {
          question: "Bai Unsubscribe",
          answer: values.isBaiUnsubscribed,
        });
      } catch (error) {
        rollbar.warn("Error tracking form answered:", error, {
          fingerprint: "tracking-form-answered-error",
          context: "stop-bai",
          petParentKbKey,
        });
      } finally {
        setSubmitting(false);
        setSubmitMessage(t(`stop_bai.answer.${values.isBaiUnsubscribed}.success_message`));
      }
    },
    children: (props: FormikProps<StopBaiFormValues>) => {
      const { isSubmitting, handleSubmit } = props;

      return (
        <Form className={styles.stopBaiForm} onChange={() => handleSubmit()}>
          {submitMessage ? (
            <p className={styles.submitMessage}>{submitMessage}</p>
          ) : (
            <>
              <Field
                type="radio"
                name="isBaiUnsubscribed"
                value={"yes"}
                id="yes"
                disabled={isSubmitting}
              />
              <label htmlFor="yes">{t("stop_bai.answer.yes")}</label>
              <Field
                type="radio"
                name="isBaiUnsubscribed"
                value={"no"}
                id="no"
                disabled={isSubmitting}
              />
              <label htmlFor="no">{t("stop_bai.answer.no")}</label>
            </>
          )}
        </Form>
      );
    },
  };

  return (
    <PageWrapper>
      <Meta
        title={undefined}
        lang={i18n.language}
        bodyClass={styles.stopBai}
        disableTranslations={false}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
        noIndex={true}
      />
      <Navigation countryCodeIso={countryCodeIso} />
      <PageStepAnimation tag="main" adoptionClassName={styles.main}>
        <PageStepForm title={t("stop_bai.title")} formData={StopBaiFormData} />
      </PageStepAnimation>
      <Footer />
      <Icons />
    </PageWrapper>
  );
};

export default StopBai;
